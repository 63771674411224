<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                申请编号：
                            </td>
                            <td>
                                <el-input v-model="searchItem.applyNum"
                                          size="small" placeholder="请输入申请编号"></el-input>
                            </td>
                            <td style="width: 80px">
                                售后工程师：
                            </td>
                            <td>
                                <el-select size="small" v-model="searchItem.afterSalesEngineerStr"
                                           style="width: 100%"
                                           value-key="id"
                                           placeholder="请选择售后工程师">
                                    <el-option
                                            v-for="item in engineerList"
                                            :key="item.engineerName"
                                            :label="item.engineerName"
                                            :value="item.engineerName">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                联系电话：
                            </td>
                            <td>
                                <el-input v-model="searchItem.contactTel"
                                          size="small" placeholder="请输入联系电话"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 100px;padding-top: 10px">
                                客户名称：
                            </td>
                            <td style="padding-top: 10px;">
                                <el-input v-model="searchItem.companyName"
                                          size="small" placeholder="请输入客户名称"></el-input>
                            </td>
                            <td style="width: 100px;padding-top: 10px">
                                申请时间：
                            </td>
                            <td style="padding-top: 10px;width: 28%">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date"
                                        placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td colspan="2" style="text-align: center;padding-top: 10px">
                                <el-button type="primary" icon="el-icon-search" size="small" style="margin-right: 10%"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus" size="small"
                                           style="margin-left: 10%" v-if="hasAuthority('after_sales_add')"
                                           @click="addAftermarket">
                                    增加
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="applyNum" width="180" label="申请编号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyName" label="客户名称"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contact" width="150" label="联系人" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contactTel" width="150" label="联系电话" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="afterSalesEngineerStr" width="150" label="售后工程师" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="createTime" width="150" label="申请时间" :formatter="dateFormat"
                                             align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="finishedDate" width="150" label="结单时间"
                                             align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{changeDateFormat(scope.row.finishedDate)}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="serviceType" width="150" label="服务类型" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.serviceType == 0">初装</span>
                                    <span v-else-if="scope.row.serviceType == 1">加装</span>
                                    <span v-else-if="scope.row.serviceType == 2">补装</span>
                                    <span v-else-if="scope.row.serviceType == 3">巡检</span>
                                    <span v-else-if="scope.row.serviceType == 4">拆卸</span>
                                    <span v-else-if="scope.row.serviceType == 5">维护</span>
                                    <span v-else-if="scope.row.serviceType == 6">远程处理</span>
                                </template>
                            </el-table-column>
                            <!---1=驳回、0=草稿、1=待售后主管审批、2=待商务部确认、3=待领导审批、10=领导审批通过、11=结束待确认、12=已结束-->
                            <el-table-column prop="auditState" width="150" label="审批状态" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.auditState == -1" style="color: red">已驳回</span>
                                    <span v-else-if="scope.row.auditState == 0">草稿</span>
                                    <span v-else-if="scope.row.auditState == 10" style="color: #20a0ff">执行中</span>
                                    <span v-else-if="scope.row.auditState == 11" style="color: #39AACE">待确认</span>
                                    <span v-else-if="scope.row.auditState == 12" style="color: green">已结单</span>
                                    <span v-else style="color: blue">待审批</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="uploadFile" label="上传附件" width="120" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-button v-if="scope.row.auditState >= 10 || scope.row.auditState  == 0 || scope.row.auditState == -1" type="text"
                                               @click="uploadFiles(scope.row.id)">上传附件
                                    </el-button>
                                    <el-button v-else type="text" disabled>上传附件</el-button>
                                </template>
                            </el-table-column>

                            <el-table-column prop="number" width="180" label="操作" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <div class="operation">
                                        <i class="iconfont iconbianji" title="编辑" @click="edit(scope.row.id)"
                                           v-if="scope.row.auditState == 0 && hasAuthority('after_sales_update')
                                           || hasAuthority('after_sales_update') && scope.row.auditState == -1"></i>
                                        <i class="iconfont iconxiangqing" title="详情" @click="show(scope.row.id)"></i>
                                        <i class="iconfont iconshanchu" title="删除" @click="del(scope.row.id)"
                                           v-if="scope.row.auditState == 0 && hasAuthority('after_sales_delete')
                                           || scope.row.auditState == -1 && hasAuthority('after_sales_delete')"></i>
                                        <i class="iconfont icontongguo" title="提交" @click="pass(scope.row.id)"
                                           v-if="scope.row.auditState == 0 && hasAuthority('after_sales_apply')
                                           || scope.row.auditState == -1 && hasAuthority('after_sales_apply')"></i>
                                        <!-- <i class="iconfont icontingzhi" title="结束" @click="end(scope.row.id)"
                                           v-if="scope.row.auditState == 10 && hasAuthority('after_sales_apply')"></i> -->
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-form">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    :close-on-click-modal="false"
                    width="70%"
                    style="margin-top: -90px"
                    center>
                <div class="edit-body">
                    <el-form :model="aftermarketForm" :rules="aftermarketRules" ref="aftermarketForm"
                             label-width="110px"
                             class="demo-ruleForm">
                        <el-row>
                            <el-col :span="10" style="width: 49.5%">
                                <el-form-item size="small" label="申请编号" prop="applyNum">
                                    <el-input size="small" v-model="aftermarketForm.applyNum"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" style="width: 49.5%">
                                <el-form-item size="small" label="项目名称" prop="contractId">
                                    <el-select size="small" v-model="aftermarketForm.projectName"
                                               @change="changeProjectName"
                                               value-key="projectName"
                                               style="width: 100%" placeholder="请选择" filterable>
                                        <el-option
                                                v-for="item in projectList"
                                                :key="item.projectName"
                                                :label="item.projectName"
                                                :value="item.projectName">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="10" style="width: 49.5%">
                                <el-form-item size="small" label="合同/协议编号" prop="contractId">
                                    <el-select size="small" v-model="aftermarketForm.contractId" style="width: 100%"
                                               @change="getDeviceList"
                                               value-key="id"
                                               placeholder="请选择合同/协议编号">
                                        <el-option
                                                v-for="item in contractList"
                                                :key="item.id"
                                                :label="item.contractNum"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" style="width: 49.5%">
                                <el-form-item size="small" label="客户名称" prop="companyName">
                                    <el-input size="small" v-model="aftermarketForm.companyName" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="10" style="width: 49.5%">
                                <el-form-item size="small" label="联系人" prop="contact">
                                    <el-input size="small" v-model="aftermarketForm.contact"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" style="width: 49.5%">
                                <el-form-item size="small" label="联系电话" prop="contactTel">
                                    <el-input size="small" v-model="aftermarketForm.contactTel"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="10" style="width: 49.5%">
                                <el-form-item size="small" label="服务类别" prop="serviceType">
                                    <el-select size="small" v-model="aftermarketForm.serviceType" style="width: 100%"
                                               @change="changeServiceType"
                                               value-key="value"
                                               placeholder="请选择">
                                        <el-option
                                                v-for="item in serviceList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" style="width: 49.5%">
                                <el-form-item size="small" label="项目地址" prop="projectAddress">
                                    <el-input size="small" v-model="aftermarketForm.projectAddress"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="10" style="width:99%">
                                <el-form-item size="small" label="备注" prop="remark">
                                    <el-input size="small" type="textarea" rows="3"
                                              v-model="aftermarketForm.remark"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div :style="{display: isShow}">
                            <el-table
                                    :data="deviceList"
                                    :max-height="tableHeight"
                                    style="width: 99%;height: 100%;"
                                    :border="true"
                                    :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                    :cell-style="{padding:'0',height: lineHeight}">
                                <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                                <el-table-column prop="deviceType" width="90" label="设备类型" align="center"
                                                 show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <span>{{scope.row.deviceType === 0?'塔机':(scope.row.deviceType === 1?'升降机':'')}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="deviceId" label="IMEI号" align="center">
                                    <template slot-scope="scope">
                                        <el-select filterable size="small" v-model="scope.row.deviceId" style="width: 100%"
                                                   @change="changeDevice(scope.$index,scope.row.deviceId)"
                                                   value-key="deviceId"
                                                   placeholder="请选择">
                                            <el-option
                                                    v-for="item in deviceDataList"
                                                    :key="item.id"
                                                    :label="item.imeiNum"
                                                    :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="simNum" label="SIM卡号" align="center" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="isVisual" width="140" label="是否吊钩可视化" align="center">
                                    <template slot-scope="scope">
                                        <el-select size="small" v-model="scope.row.isVisual" style="width: 100%"
                                                   value-key="value"
                                                   placeholder="请选择">
                                            <el-option
                                                    v-for="item in isList"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column width="80" label="操作" align="center"
                                                 show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <div class="operation">
                                            <i class="iconfont iconzengjia  "
                                               style="color: #20a0ff;font-size: 20px;margin-left: 10px"
                                               title="新增设备" @click="addDevice(scope.$index)"></i>
                                            <i class="iconfont iconshanchu" style="color: red;font-size: 20px;"
                                               title="删除设备" @click="delDevice(scope.$index)"></i>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-row>
                                <el-col :span="10" style="width: 69.5%;margin-top: 20px">
                                    <el-form-item size="small" label="工程师" prop="name">
                                        <el-select size="small" v-model="engineers" clearable multiple
                                                   style="width: 100%"
                                                   placeholder="请选择工程师">
                                            <el-option
                                                    v-for="item in engineerList"
                                                    :key="item.id"
                                                    :label="item.engineerName"
                                                    :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10" style="width: 29.5%;margin-top: 20px">
                                    <el-form-item size="small" label="是否特殊地区" prop="isSpecialArea">
                                        <el-select size="small" v-model="aftermarketForm.isSpecialArea"
                                                   style="width: 100%"
                                                   value-key="value"
                                                   placeholder="请选择是否特殊地区">
                                            <el-option
                                                    v-for="item in isList"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div :style="{display: isExpend}">
                                <el-row>
                                    <el-col :span="10" style="width: 49.5%">
                                        <el-form-item size="small" label="申请人" prop="applyUserId">
                                            <el-select size="small" v-model="aftermarketForm.applyUserId"
                                                       style="width: 100%"
                                                       value-key="id"
                                                       placeholder="请选择申请人">
                                                <el-option
                                                        v-for="item in engineerList"
                                                        :key="item.id"
                                                        :label="item.engineerName"
                                                        :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10" style="width: 49.5%">
                                        <el-form-item size="small" label="现场处理" prop="siteUserId">
                                            <el-select size="small" v-model="aftermarketForm.siteUserId"
                                                       style="width: 100%"
                                                       value-key="id"
                                                       placeholder="无">
                                                <el-option
                                                        v-for="item in engineerList"
                                                        :key="item.id"
                                                        :label="item.engineerName"
                                                        :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10" style="width: 99%">
                                        <el-form-item size="small" label="发货信息" prop="logistic">
                                            <el-input size="small" type="textarea" rows="3"
                                                      v-model="aftermarketForm.logistic"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10" style="width: 99%">
                                        <el-form-item size="small" label="故障描述" prop="trouble">
                                            <el-input size="small" type="textarea" rows="3"
                                                      v-model="aftermarketForm.trouble"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10" style="width: 49.5%">
                                        <el-form-item size="small" label="是否保质期" prop="isInWarranty">
                                            <el-select size="small" v-model="aftermarketForm.isInWarranty"
                                                       style="width: 100%"
                                                       value-key="value"
                                                       placeholder="请选择是否保质期">
                                                <el-option
                                                        v-for="item in isList"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10" style="width: 49.5%">
                                        <el-form-item size="small" label="售后更换方式" prop="changeMethod">
                                            <el-select size="small" v-model="aftermarketForm.changeMethod"
                                                       style="width: 100%"
                                                       value-key="value"
                                                       placeholder="请选择售后更换方式">
                                                <el-option
                                                        v-for="item in isChangeList"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10" style="width: 99%">
                                        <el-form-item size="small" label="远程处理结果" prop="remoteDealResult">
                                            <el-input size="small" type="textarea" rows="3"
                                                      v-model="aftermarketForm.remoteDealResult"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="10" style="width: 49.5%">
                                        <el-form-item size="small" label="工程师" prop="remoteDealUserId">
                                            <el-select size="small" v-model="aftermarketForm.remoteDealUserId"
                                                       style="width: 100%"
                                                       value-key="id"
                                                       placeholder="请选择工程师">
                                                <el-option
                                                        v-for="item in engineerList"
                                                        :key="item.id"
                                                        :label="item.engineerName"
                                                        :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10" style="width: 49.5%">
                                        <el-form-item size="small" label="时间" prop="dealDate">
                                            <el-date-picker
                                                    v-model="dealDate"
                                                    type="date"
                                                    style="width: 100%"
                                                    value-format="yyyy-MM-dd"
                                                    formart="yyyy-MM-dd"
                                                    placeholder="选择日期">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <div class="table-material">
                                    <div>
                                        <table width="70%">
                                            <tr>
                                                <td style="width: 100px;">
                                                    物料编码：
                                                </td>
                                                <td>
                                                    <el-input v-model="searchBasicItem.materialCode" size="small"
                                                              placeholder="请输入物料编码"
                                                    ></el-input>
                                                </td>
                                                <td style="width: 100px">
                                                    选择部件：
                                                </td>
                                                <td>
                                                    <el-select v-model="searchBasicItem.component" style="width: 100%"
                                                               size="small"
                                                               clearable
                                                               filterable placeholder="请选择部件名称">
                                                        <el-option
                                                                v-for="(item,index) in componentList"
                                                                :key="index"
                                                                :label="item.componentName"
                                                                :value="item.component">
                                                        </el-option>
                                                    </el-select>
                                                </td>
                                                <td style="width: 100px;">
                                                    产品名称：
                                                </td>
                                                <td>
                                                    <el-input v-model="searchBasicItem.materialName" size="small"
                                                              placeholder="请输入产品名称"
                                                    ></el-input>
                                                </td>
                                                <td style="text-align: center;width: 200px">
                                                    <el-button type="primary" icon="el-icon-search" size="small"
                                                               @click="searchBasicBtn">搜索
                                                    </el-button>
                                                    <el-button type="primary" icon="el-icon-refresh" size="small"
                                                               @click="resetBasicBtn">重置
                                                    </el-button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="material-table">
                                        <el-table
                                                ref="multipleTable"
                                                :data="tableList"
                                                tooltip-effect="dark"
                                                style="width: 99%"
                                                max-height="350"
                                                border
                                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                                :cell-style="{padding:'0',height: lineHeight}"
                                                @selection-change="checkAll"
                                        >
                                            <el-table-column type="selection" width="40" align="center">
                                            </el-table-column>
                                            <el-table-column prop="materialCode" label="物料编码" align="center"
                                                             show-overflow-tooltip></el-table-column>
                                            <el-table-column prop="materialName" label="产品名称" align="center"
                                                             show-overflow-tooltip></el-table-column>
                                            <el-table-column prop="useTypeName" label="适用机型" align="center"
                                                             show-overflow-tooltip></el-table-column>
                                            <el-table-column prop="componentName" label="部件名称" align="center"
                                                             show-overflow-tooltip></el-table-column>
                                            <el-table-column prop="model" label="型号" align="center" 
                                                             show-overflow-tooltip></el-table-column>
                                            <el-table-column prop="unitName" label="单位" align="center" width="80"
                                                             show-overflow-tooltip></el-table-column>
                                            <el-table-column prop="count" label="数量" align="center"
                                                             show-overflow-tooltip>
                                                <template slot-scope="scope">
                                                    <el-input type="tel" size="small"
                                                              onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                                              v-if="scope.row.checked == false"
                                                              v-model.number="scope.row.count"
                                                              placeholder="请输入数量" disabled></el-input>
                                                    <el-input type="tel" size="small" v-else
                                                              v-model.number="scope.row.count"
                                                              onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                                              placeholder="请输入数量"></el-input>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>

                            <div class="upload-file">
                                <div style="width: 80px;">文件留底：</div>
                                <div class="button-style" @click="uploadFile()"
                                     title="支持扩展名：.rar .zip .doc .docx .pdf .jpg...">文件上传
                                </div>
                                <div style="margin-top: 2px;margin-left: 20px">
                                    <span style="color: blue;">{{aftermarketForm.attachmentUrl != null?aftermarketForm.attachmentUrl.split('/').slice(-1) + '':''}}</span>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('aftermarketForm')">取 消</el-button>
                    <el-button type="primary" @click="submit('aftermarketForm')"
                               :loading="isLoad">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="upload-dialog">
            <el-dialog
                    :title="uploadTitle"
                    :visible.sync="uploadDialog"
                    width="410px"
                    :close-on-click-modal="false"
                    center>
                <el-upload
                        class="upload-demo"
                        drag
                        ref="upload"
                        style="width: 100%"
                        :action='url'
                        :on-success="getSuccessData"
                        :on-error="getErrorData"
                        multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">{{tipMessage}}</div>
                </el-upload>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelFile">取 消</el-button>
                    <el-button type="primary" @click="submitFile">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="info-dialog">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoDialogVisible"
                    style="margin-top: -90px"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <div style="max-height: 650px;overflow-y: auto">
                    <table width="100%">
                        <tr>
                            <td width="85px">申请编号：</td>
                            <td class="td-style">{{aftermarketDisplay.applyNum}}</td>
                            <td width="85px">合同编号：</td>
                            <td class="td-style">{{aftermarketDisplay.contractNum}}</td>
                            <td width="85px">客户名称：</td>
                            <td class="td-style">{{aftermarketDisplay.companyName}}</td>
                        </tr>
                        <tr>
                            <td width="85px">项目名称：</td>
                            <td class="td-style">{{aftermarketDisplay.projectName}}</td>
                            <td width="85px">联系人：</td>
                            <td class="td-style">{{aftermarketDisplay.contact}}</td>
                            <td width="85px">联系电话：</td>
                            <td class="td-style">{{aftermarketDisplay.contactTel}}</td>
                        </tr>
                        <tr>
                            <td width="85px">服务类别：</td>
                            <td class="td-style">
                                <span v-if="aftermarketDisplay.serviceType == 0">初装</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 1">加装</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 2">补装</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 3">巡检</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 4">拆卸</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 5">维护</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 6">远程处理</span>
                            </td>
                            <td width="85px">项目地址：</td>
                            <td class="td-style" colspan="3">{{aftermarketDisplay.projectAddress}}</td>
                        </tr>
                        <tr>
                            <td width="85px">现场工程师：</td>
                            <td class="td-style" colspan="3">{{aftermarketDisplay.afterSalesEngineerStr}}</td>
                            <td width="85px">是否特殊地区：</td>
                            <td class="td-style">{{aftermarketDisplay.isSpecialArea}}</td>
                        </tr>

                        <tr v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6">
                            <td width="85px">申请人：</td>
                            <td class="td-style">{{aftermarketDisplay.applyUserName}}</td>
                            <td width="85px">现场处理：</td>
                            <td class="td-style">{{aftermarketDisplay.siteUserName}}</td>
                            <td width="85px">是否保质期：</td>
                            <td class="td-style">{{aftermarketDisplay.isInWarranty}}</td>
                        </tr>
                        <tr v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6">
                            <td width="85px">售后更换方式：</td>
                            <td class="td-style">{{aftermarketDisplay.changeMethod}}</td>
                            <td width="85px">工程师：</td>
                            <td class="td-style">{{aftermarketDisplay.remoteDealUserName}}</td>
                            <td width="85px">时间：</td>
                            <td class="td-style">{{aftermarketDisplay.dealDate.split(' ')[0]}}</td>
                        </tr>
                        <tr v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6">
                            <td width="85px">故障描述：</td>
                            <td class="td-style" colspan="5">
                                {{aftermarketDisplay.trouble}}
                            </td>
                        </tr>
                        <tr v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6">
                            <td width="85px">发货信息：</td>
                            <td class="td-style" colspan="5">
                                {{aftermarketDisplay.logistic}}
                            </td>
                        </tr>
                        <tr v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6">
                            <td width="85px">远程处理结果：</td>
                            <td class="td-style" colspan="5">
                                {{aftermarketDisplay.remoteDealResult}}
                            </td>
                        </tr>
                        <tr>
                            <td width="85px">备注：</td>
                            <td class="td-style" colspan="5">
                                {{aftermarketDisplay.remark}}
                            </td>
                        </tr>
                    </table>
                    <el-table
                            :data="showDeviceList"
                            :max-height="tableHeight"
                            style="width: 99%;height: 100%;margin-top: 10px"
                            :border="true"
                            :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                            :cell-style="{padding:'0',height: lineHeight}">
                        <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                        <el-table-column prop="deviceType" width="90" label="设备类型" align="center"
                                         show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>{{scope.row.deviceType==0?'塔机':'升降机'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="imeiNum" label="IMEI号" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="simNum" label="SIM卡号" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="isVisual" width="140" label="是否吊钩可视化" align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.isVisual==0?'否':'是'}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table
                            v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6"
                            ref=" Material"
                            :data="tableList"
                            tooltip-effect="dark"
                            style="width: 100%;margin-top: 10px"
                            max-height="350"
                            border
                            :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                            :cell-style="{padding:'0',height: lineHeight}"
                    >
                        <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                        <el-table-column prop="materialCode" label="物料编码" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="materialName" label="产品名称" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="useTypeName" label="适用机型" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="componentName" label="部件名称" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="model" label="型号" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="count" label="数量" align="center"
                                         show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                    <div class="approval-table">
                        <div class="text-title">
                            审批记录
                        </div>
                        <template>
                            <el-table
                                    :data="approvalRecord"
                                    border
                                    style="width: 100%">
                                <el-table-column prop="depName" label="部门">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.configId == 13">总经理</span>
                                        <span v-else-if="scope.row.configId == 12">财务部</span>
                                        <span v-else-if="scope.row.configId == 11">生产部</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="createUserName" label="审批人"></el-table-column>
                                <el-table-column prop="auditRemark" label="意见">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.auditRemark == null">无</span>
                                        <span v-else>{{scope.row.auditRemark}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="时间" :formatter="dateFormat">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.createTime == null">无</span>
                                        <span v-else>{{scope.row.createTime}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.isPass == 0 && scope.row.isEffective == 1"
                                              style="color: red">驳回</span>
                                        <span v-else-if="scope.row.isPass == 0 && scope.row.isEffective == 0"
                                              style="color: #F08080">驳回</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 1"
                                              style="color: green">通过</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 0"
                                              style="color: #98FB98">通过</span>
                                        <span v-else style="color: blue">未审批</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                    <div style="margin-top: 10px;">
                        <span style="margin-right: 20px">附件信息：</span>
                        <a style="color: blue;" :href="downloadUrl + 'erp' + aftermarketDisplay.attachmentUrl">
                            {{aftermarketDisplay.attachmentUrl != null?
                            aftermarketDisplay.attachmentUrl.split('/').slice(-1) + '':''}}</a>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    var pathURL = process.env.VUE_APP_CURENV;

    export default {
        data() {
            return {
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                applyId: '',
                url: '',
                uploadType: '',
                uploadTitle: '',
                downloadUrl: pathURL,
                showTitle: '',
                tipMessage: '',
                isShow: 'none',
                isExpend: 'none',
                tableData: [],
                tableList: [],
                showDeviceList: [],
                deviceList: [
                    {
                        deviceType: null,
                        deviceId: null,
                        simNum: null,
                        imeiNum: null,
                    }
                ],
                option: {
                    pageSize: 15,
                    pageIndex: 1,
                },
                searchBasicItem: {
                    materialCode: '',
                    component: '',
                    materialName:'',
                },
                componentList: [],
                totalPage: 10,
                isSpecial: 0,
                editDialogVisible: false,
                infoDialogVisible: false,
                uploadDialog: false,
                lineHeight: '',
                editTitle: '',
                searchItem: {
                    applyNum: '',
                    afterSalesEngineerStr: '',
                    contactTel: '',
                    isAudit: 0,
                    companyName: '',
                    endDate: '',
                    startDate: '',
                    pageSize: 15,
                    pageIndex: 1
                },
                serviceList: [
                    {
                        value: 0,
                        label: '初装'
                    },
                    {
                        value: 1,
                        label: '加装'
                    },
                    {
                        value: 2,
                        label: '补装'
                    },
                    {
                        value: 3,
                        label: '巡检'
                    },
                    {
                        value: 4,
                        label: '拆卸'
                    },
                    {
                        value: 5,
                        label: '维护'
                    },
                    {
                        value: 6,
                        label: '远程处理'
                    },
                ],
                projectList: [],
                contractList: [],
                isList: [
                    {
                        value: 0,
                        label: '否'
                    },
                    {
                        value: 1,
                        label: '是'
                    }
                ],
                isChangeList: [
                    {
                        value: 0,
                        label: '客户自换'
                    },
                    {
                        value: 1,
                        label: '现场更换'
                    }
                ],
                aftermarketForm: {
                    id: null,
                    applyNum: '',
                    contractId: '',
                    projectName: '',
                    companyName: '',
                    contact: '',
                    contactTel: '',
                    projectAddress: '',
                    serviceType: '',
                    isSpecialArea: '',
                    applyUserId: '',
                    siteUserId: '',
                    logistic: '',
                    trouble: '',
                    isInWarranty: '',
                    changeMethod: '',
                    remoteDealResult: '',
                    remoteDealUserId: '',
                    dealDate: '',
                    attachmentUrl: '',
                    remark: '',
                    afterSalesEngineerStr: '',
                    afterSalesMaterialStr: '',
                    afterSalesDeviceStr: ''
                },
                aftermarketDisplay: {
                    id: null,
                    applyNum: '',
                    contractId: '',
                    contractNum: '',
                    projectName: '',
                    companyName: '',
                    contact: '',
                    contactTel: '',
                    projectAddress: '',
                    serviceType: '',
                    isSpecialArea: '',
                    applyUserId: '',
                    applyUserName: '',
                    siteUserId: '',
                    siteUserName: '',
                    logistic: '',
                    trouble: '',
                    isInWarranty: '',
                    changeMethod: '',
                    remoteDealResult: '',
                    remoteDealUserId: '',
                    remoteDealUserName: '',
                    dealDate: '',
                    remark: '',
                    attachmentUrl: '',
                    afterSalesEngineerStr: '',
                    afterSalesMaterialStr: '',
                    afterSalesDeviceStr: ''
                },
                dealDate: '',
                remark: '',
                engineerList: [],
                deviceDataList: [],
                deviceDataListStr: '',
                engineers: [],
                aftermarketRules: {
                    applyNum: [
                        {required: true, message: '请输入申请编号', trigger: 'blur'},
                    ],
                    contractId: [
                        {required: true, message: '请输入合同/协议编号', trigger: 'blur'},
                    ],
                    projectName: [
                        {required: true, message: '请输入项目名称', trigger: 'blur'},
                    ],
                    companyName: [
                        {required: true, message: '请输入客户名称', trigger: 'blur'},
                    ],
                    contact: [
                        {required: true, message: '请输入联系人', trigger: 'blur'},
                    ],
                    contactTel: [
                        {required: true, message: '请输入联系电话', trigger: 'blur'},
                    ],
                    projectAddress: [
                        {required: true, message: '请输入项目地址', trigger: 'blur'},
                    ],
                    serviceType: [
                        {required: true, message: '请输入服务类别', trigger: 'blur'},
                    ],
                },
                isLoad: false,
                type: 0,
                checkList: [],
                multipleSelection: [],
                tableListStr: '',
                uploadId: '',
                approvalRecord: [],
            };
        },
        methods: {
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pageAftermarketList(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.totalPage = res.data.totalElements;
                        this.tableData = res.data.content;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageAftermarketList(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.totalPage = res.data.totalElements;
                        this.tableData = res.data.content;
                    }
                })
            },
            reset() {
                this.searchItem = {
                    applyNum: '',
                    afterSalesEngineerStr: '',
                    isAudit: 0,
                    contactTel: '',
                    companyName: '',
                    endDate: '',
                    startDate: '',
                    pageSize: 15,
                    pageIndex: 1
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            uploadFile() {
                this.url = pathURL + 'erp/file/upload?businessType=after_sale&access_token=' + localStorage.getItem("accessToken")
                this.uploadDialog = true
                this.uploadTitle = '上传其它附件'
                this.tipMessage = '可以上传*.xlsx,*.xls,*.doc,*.png等';
                this.uploadId = ''
            },
            getSuccessData(res) {
                if (res.code == 200){
                    this.aftermarketForm.attachmentUrl = res.data.filePath;
                }else {
                    this.$message.error(res.message)
                }
            },
            getErrorData(res) {
                this.$message.error(res.message)
            },
            cancelFile() {
                this.uploadDialog = false;
                this.$refs.upload.clearFiles();
            },
            submitFile() {
                this.uploadDialog = false;
                if (this.uploadId){
                    this.$api.uploadAftermarket({id: this.uploadId, attachmentUrl: this.aftermarketForm.attachmentUrl}).then(res => {
                        if (res.code == 200){
                            this.$message.success(res.message);
                        }else{
                            this.$message.error(res.message);
                        }
                    })
                }
                this.$refs.upload.clearFiles();
            },
            addAftermarket() {
                this.type = 0;
                this.uploadId = ''
                this.isExpend = 'none'
                this.isShow = 'none'
                this.dealDate = ''
                this.tableList = JSON.parse(this.tableListStr)
                this.isLoad = false;
                this.editDialogVisible = true;
                this.editTitle = '新增售后申请信息'
                this.deviceList = [{
                    deviceType: '',
                    deviceId: '',
                    simNum: '',
                    imeiNum: '',
                }];
                this.checkList = [];
                this.aftermarketForm = {
                    id: null,
                    applyNum: '',
                    contractId: '',
                    projectName: '',
                    companyName: '',
                    contact: '',
                    contactTel: '',
                    projectAddress: '',
                    serviceType: '',
                    isSpecialArea: 0,
                    applyUserId: '',
                    siteUserId: '',
                    logistic: '',
                    trouble: '',
                    isInWarranty: '',
                    changeMethod: '',
                    remoteDealResult: '',
                    remoteDealUserId: '',
                    remark: '',
                    dealDate: '',
                    attachmentUrl: '',
                    afterSalesEngineerStr: '',
                    afterSalesMaterialStr: '',
                    afterSalesDeviceStr: ''
                }
            },
            edit(id) {
                this.type = 1;
                this.isShow = 'block'
                this.isExpend = 'none'
                this.tableList = [];
                this.uploadId = ''
                this.$api.getAftermarketById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.aftermarketForm = {
                            id: res.data.id,
                            projectName: '',
                            companyName: '',
                            applyNum: res.data.applyNum,
                            contractId: res.data.contractId,
                            contact: res.data.contact,
                            contactTel: res.data.contactTel,
                            projectAddress: res.data.projectAddress,
                            serviceType: res.data.serviceType,
                            isSpecialArea: res.data.isSpecialArea,
                            applyUserId: res.data.applyUserId,
                            siteUserId: res.data.siteUserId,
                            logistic: res.data.logistic,
                            trouble: res.data.trouble,
                            isInWarranty: res.data.isInWarranty,
                            changeMethod: res.data.changeMethod,
                            remoteDealResult: res.data.remoteDealResult,
                            remoteDealUserId: res.data.remoteDealUserId,
                            attachmentUrl: res.data.attachmentUrl,
                            dealDate: res.data.dealDate ? res.data.dealDate.split(' ')[0] : '',
                            afterSalesEngineerStr: res.data.afterSalesEngineerStr,
                            afterSalesMaterialStr: res.data.afterSalesMaterialStr,
                            afterSalesDeviceStr: res.data.afterSalesDeviceStr,
                            remark: res.data.remark,
                        }
                        this.editId = res.data.id
                        this.editTitle = '编辑《' + res.data.applyNum + '》详情'
                        this.deviceList = res.data.afterSalesDeviceList;
                        this.tableList = res.data.afterSalesMaterialList;
                        var newArr = []
                        for (var item of res.data.afterSalesEngineerList) {
                            newArr.push(item.engineerId);
                        }
                        this.engineers = newArr;
                        this.dealDate = res.data.dealDate ? res.data.dealDate.split(' ')[0] : ''
                        if (res.data.serviceType == 5 || res.data.serviceType == 6) {
                            this.isExpend = 'block'
                        }
                        for (var item of this.projectList) {
                            if (JSON.stringify(item.contractList).indexOf(res.data.contractId) != -1) {
                                this.aftermarketForm.projectName = item.projectName;
                                this.editProjectName(item.projectName, res.data.contractId)
                                break;
                            }
                        }
                        var materialArr = JSON.parse(this.tableListStr)
                        var materialList = [];
                        for (var item of this.tableList) {
                            for (var itemObj of materialArr) {
                                if (item.materialId == itemObj.id) {
                                    itemObj.checked = true;
                                    itemObj.count = item.count
                                    materialList.push(itemObj);
                                }
                            }
                        }
                        for (var item of materialArr) {
                            materialList.push(item);
                        }
                        var checkedArr = []
                        for (const item of this.uniqueId(materialList)) {
                            if (item.checked) {
                                checkedArr.push(item)
                            }
                        }
                        this.toggleSelection(checkedArr)
                        this.tableList = this.uniqueId(materialList);
                        this.editDialogVisible = true;
                    }
                })
            },
            toggleSelection(rows) {
                if (rows) {
                    this.$nextTick(function () {
                        for (var row of rows) {
                            this.$refs.multipleTable.toggleRowSelection(row, true)
                        }
                    })
                }
            },
            show(id) {
                this.deviceList = [];
                this.$api.getAftermarketById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.aftermarketDisplay = {
                            id: res.data.id,
                            applyNum: res.data.applyNum,
                            applyUserName: res.data.applyUserName,
                            siteUserName: res.data.siteUserName,
                            remoteDealUserName: res.data.remoteDealUserName,
                            contractId: res.data.contractId,
                            companyName: res.data.companyName,
                            contact: res.data.contact,
                            contactTel: res.data.contactTel,
                            projectAddress: res.data.projectAddress,
                            serviceType: res.data.serviceType,
                            isSpecialArea: res.data.isSpecialArea == 0 ? "否" : '是',
                            applyUserId: res.data.applyUserId,
                            siteUserId: res.data.siteUserId,
                            logistic: res.data.logistic,
                            trouble: res.data.trouble,
                            remark: res.data.remark,
                            isInWarranty: res.data.isInWarranty == 0 ? "否" : '是',
                            changeMethod: res.data.changeMethod == 0 ? "客户自换" : '现场更换',
                            remoteDealResult: res.data.remoteDealResult,
                            remoteDealUserId: res.data.remoteDealUserId,
                            attachmentUrl: res.data.attachmentUrl,
                            dealDate: res.data.dealDate ? res.data.dealDate.split(' ')[0] : '',
                        }
                        this.showDeviceList = res.data.afterSalesDeviceList;
                        this.tableList = res.data.afterSalesMaterialList;
                        this.showTitle = '查看《' + res.data.applyNum + '》详情'
                        for (var item of this.projectList) {
                            if (JSON.stringify(item.contractList).indexOf(res.data.contractId) != -1) {
                                this.aftermarketDisplay.projectName = item.projectName;
                                this.showProjectName(item.contractList, res.data.contractId)
                                break;
                            }
                        }
                        var engineerListStr = '';
                        for (const item of res.data.afterSalesEngineerList) {
                            for (var itemObj of this.engineerList) {
                                if (engineerListStr == '' && itemObj.id == item.engineerId) {
                                    engineerListStr = itemObj.engineerName
                                } else if (itemObj.id == item.engineerId) {
                                    engineerListStr = engineerListStr + '，' + itemObj.engineerName
                                }
                            }
                        }
                        this.aftermarketDisplay.afterSalesEngineerStr = engineerListStr;
                        if(!this.tableListStr) {
                            this.tableListStr = '[]'
                        }
                        var materialArr = JSON.parse(this.tableListStr)
                        var materialList = [];
                        for (var item of this.tableList) {
                            for (var itemObj of materialArr) {
                                if (item.materialId == itemObj.id) {
                                    itemObj.checked = true;
                                    itemObj.count = item.count
                                    materialList.push(itemObj);
                                }
                            }
                        }
                        this.tableList = this.uniqueId(materialList);
                        this.infoDialogVisible = true;
                    }
                });
                this.$api.getApprovalRecord({businessId:id}).then(res=>{
                    if(res.code != 200) return;
                    let tempData = res.data;
                    if(tempData.length < 3){
                        const configId1 = tempData.findIndex((item=>{
                            return item.configId == 11
                        }))
                        const configId2 = tempData.findIndex((item=>{
                            return item.configId == 12
                        }))
                        const configId3 = tempData.findIndex((item=>{
                            return item.configId == 13
                        }))
                        if(configId1 == -1){
                            tempData.push({
                                isPass:'未审批',
                                configId: 11,
                                createTime: '无',
                                createUserName:'无',
                                auditRemark:'无',
                            })
                        }
                        if(configId2 == -1){
                            tempData.push({
                                isPass:'未审批',
                                configId: 12,
                                createTime: '无',
                                createUserName:'无',
                                auditRemark:'无',
                            })
                        }
                        if(configId3 == -1){
                            tempData.push({
                                isPass:'未审批',
                                configId: 13,
                                createTime: '无',
                                createUserName:'无',
                                auditRemark:'无',
                            })
                        }
                    }
                    this.approvalRecord = tempData
                })

            },
            del(id) {
                this.$confirm('你确定要删除该申请单信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.delAftermarketById({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh")
                            this.$message.success(res.message);
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                }).catch(() => {
                })
            },
            pass(id) {
                this.$confirm('你确定要提交该售后申请吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.submitAftermarketById({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh")
                            this.$message.success(res.message);
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                }).catch(() => {
                })
            },
            end(id) {
                this.$confirm('你确定要结束该申请信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.endAftermarketById({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh");
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.editDialogVisible = false;
            },
            submit(formName) {
                this.isLoad = true;
                var deviceListStr = '';
                var engineerListStr = '';
                var materialListStr = '';
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        for (var item of this.deviceList) {
                            item.isVisual = '' + item.isVisual
                            if (!item.isVisual) {
                                this.$message.error(item.imeiNum + '的是否吊钩可视化不能为空');
                                this.isLoad = false;
                                return;
                            } else {
                                if (deviceListStr == '') {
                                    deviceListStr = item.deviceId + ',' + item.isVisual
                                } else {
                                    deviceListStr = deviceListStr + '|' + item.deviceId + ',' + item.isVisual
                                }
                            }
                        }
                        for (var item of this.tableList) {
                            if (item.checked) {
                                if (!item.count) {
                                    this.$message.error(item.materialName + '的数量不能为空');
                                    this.isLoad = false;
                                    return;
                                } else {
                                    if (materialListStr == '') {
                                        materialListStr = item.id + ',' + item.count
                                    } else {
                                        materialListStr = materialListStr + '|' + item.id + ',' + item.count
                                    }
                                }
                            }
                        }
                        this.aftermarketForm.afterSalesDeviceStr = deviceListStr;
                        this.aftermarketForm.afterSalesMaterialStr = materialListStr;
                        for (var item of this.engineers) {
                            if (engineerListStr == '') {
                                engineerListStr = item
                            } else {
                                engineerListStr = engineerListStr + '|' + item
                            }
                        }
                        this.aftermarketForm.afterSalesEngineerStr = engineerListStr;
                        if (!this.aftermarketForm.afterSalesEngineerStr) {
                            this.$message.error('工程师不能为空');
                            this.isLoad = false;
                            return;
                        }
                        if (!this.aftermarketForm.afterSalesDeviceStr) {
                            this.$message.error('设备信息不能为空');
                            this.isLoad = false;
                            return;
                        }
                        this.aftermarketForm.isSpecialArea = '' + this.aftermarketForm.isSpecialArea;
                        this.aftermarketForm.isInWarranty = '' + this.aftermarketForm.isInWarranty;
                        this.aftermarketForm.changeMethod = '' + this.aftermarketForm.changeMethod;
                        this.aftermarketForm.dealDate = this.dealDate == '' ? null : (this.dealDate + ' 00:00:00');
                        if (!this.aftermarketForm.isSpecialArea) {
                            this.$message.error('特殊地区不能为空');
                            this.isLoad = false;
                            return;
                        }
                        if (this.aftermarketForm.serviceType == 6 || this.aftermarketForm.serviceType == 5) {
                            if (!this.aftermarketForm.applyUserId) {
                                this.$message.error('申请人不能为空');
                                this.isLoad = false;
                                return;
                            }
                            // if (!this.aftermarketForm.siteUserId) {
                            //     this.$message.error('现场工程师人员不能为空');
                            //     this.isLoad = false;
                            //     return;
                            // }
                            if (!this.aftermarketForm.remoteDealUserId) {
                                this.$message.error('远程处理工程师人员不能为空');
                                this.isLoad = false;
                                return;
                            }
                            if (!this.aftermarketForm.logistic) {
                                this.$message.error('发货信息不能为空');
                                this.isLoad = false;
                                return;
                            }
                            if (!this.aftermarketForm.trouble) {
                                this.$message.error('故障描述不能为空');
                                this.isLoad = false;
                                return;
                            }
                            if (!this.aftermarketForm.isInWarranty) {
                                this.$message.error('是否保质期不能为空');
                                this.isLoad = false;
                                return;
                            }
                            if (!this.aftermarketForm.changeMethod) {
                                this.$message.error('售后更换方式不能为空');
                                this.isLoad = false;
                                return;
                            }
                            if (!this.aftermarketForm.trouble) {
                                this.$message.error('远程处理结果不能为空');
                                this.isLoad = false;
                                return;
                            }
                            if (!this.aftermarketForm.dealDate) {
                                this.$message.error('时间不能为空');
                                this.isLoad = false;
                                return;
                            }
                        }
                        if (this.type == 0) {
                            this.$api.addAftermarket(this.aftermarketForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.editDialogVisible = false;
                                    this.$message.success(res.message);
                                    this.isLoad = false;
                                } else {
                                    this.$message.error(res.message);
                                    this.isLoad = false;
                                }
                            })
                        } else {
                            this.$api.updateAftermarket(this.aftermarketForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.editDialogVisible = false;
                                    this.$message.success(res.message);
                                    this.isLoad = false;
                                } else {
                                    this.$message.error(res.message);
                                    this.isLoad = false;
                                }
                            })
                        }
                    } else {
                        this.isLoad = false
                        return false;
                    }
                });
            },
            uploadFiles(id) {
                /*this.uploadType = 'afterSales';
                this.uploadDialog = true;
                this.applyId = id;*/
                this.url = pathURL + 'erp/file/upload?businessType=after_sale&access_token=' + localStorage.getItem("accessToken")
                this.uploadDialog = true
                this.uploadTitle = '上传其它附件'
                this.uploadId = id;
                this.tipMessage = '可以上传*.xlsx,*.xls,*.doc,*.png等';
            },
            openDialog(val) {
                this.uploadDialog = val;
            },
            changeProjectName(val) {
                this.aftermarketForm.contractId = ''
                for (var item of this.projectList) {
                    if (item.projectName == val) {
                        this.aftermarketForm.companyName = item.companyName;
                        this.contractList = item.contractList;
                    }
                }
                this.aftermarketForm.contractId = this.contractList[0].id
                this.getDeviceList(this.contractList[0].id);
            },
            editProjectName(val, contractId) {
                for (var item of this.projectList) {
                    if (item.projectName == val) {
                        this.aftermarketForm.companyName = item.companyName;
                        this.contractList = item.contractList;
                    }
                }
                this.getEditDeviceList(contractId)
            },
            showProjectName(val, contractId) {
                for (var item of val) {
                    if (item.id == contractId) {
                        this.aftermarketDisplay.contractNum = item.contractNum;
                    }
                }
                this.$api.getDeviceListById({contractId: contractId}).then(res => {
                    if (res.code == 200) {
                        if (res.data.length > 0) {
                            for (var item of this.showDeviceList) {
                                for (var itemObj of res.data) {
                                    if (item.deviceId == itemObj.id) {
                                        item.deviceType = itemObj.deviceType;
                                        item.deviceId = itemObj.id;
                                        item.simNum = itemObj.simNum;
                                        item.imeiNum = itemObj.imeiNum;
                                    }
                                }
                            }
                            this.$forceUpdate();
                        } else {
                            this.$message.error("该合同信息还没有进行发货操作")
                            return;
                        }
                    }
                })
            },
            getDeviceList(val) {

                this.$api.getDeviceListById({contractId: val}).then(res => {
                    if (res.code == 200) {
                        if (res.data.length > 0) {
                            this.devicelist = [{}]
                            this.deviceDataList = res.data
                            this.deviceDataListStr = JSON.stringify(res.data);
                        } else {
                            this.$message.error("该合同信息还没有进行发货操作")
                            this.devicelist = []
                            return;
                        }
                    }
                })
            },
            getEditDeviceList(val) {
                this.$api.getDeviceListById({contractId: val}).then(res => {
                    if (res.code == 200) {
                        if (res.data.length > 0) {
                            this.deviceDataList = res.data
                            this.deviceDataListStr = JSON.stringify(res.data);
                            for (var item of this.deviceList) {
                                for (var itemObj of res.data) {
                                    if (item.deviceId == itemObj.id) {
                                        item.deviceType = itemObj.deviceType;
                                        item.deviceId = itemObj.id;
                                        item.simNum = itemObj.simNum;
                                        item.imeiNum = itemObj.imeiNum;
                                    }
                                }
                            }
                        } else {
                            this.$message.error("该合同信息还没有进行发货操作")
                            return;
                        }
                    }
                })
            },
            changeDevice(index, value) {
                var newArr = JSON.parse(this.deviceDataListStr);
                for (var item of this.deviceDataList) {
                    if (item.id == value) {
                        this.deviceList[index].deviceType = item.deviceType;
                        this.deviceList[index].deviceId = item.id;
                        this.deviceList[index].simNum = item.simNum;
                        this.deviceList[index].imeiNum = item.imeiNum;
                    }
                }
                let filter = []
                for (var item of this.deviceList) {
                    filter = newArr.filter(
                        (itemObj) => itemObj.id != item.deviceId
                    );
                    newArr = filter;
                }
                this.deviceDataList = filter;
            },
            changeServiceType(val) {
                this.isShow = 'block'
                if (val === 5) {
                    this.isExpend = 'block'
                } else if (val === 6) {
                    this.isExpend = 'block'
                } else {
                    this.isExpend = 'none'
                }
            },
            checkAll(val) {
                var flag = false;
                if (this.uniqueId(this.multipleSelection).length < val.length) {
                    flag = true;
                }
                if (flag) {
                    for (var item of this.tableList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (var item of this.tableList) {
                        item.checked = false;
                    }
                    for (var item of this.tableList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                }
                this.multipleSelection = val
            },
            initData() {
                this.$api.getEngineerList().then(res => {
                    if (res.code == 200) {
                        this.engineerList = res.data;
                    }
                });
                this.$api.getProjectList().then(res => {
                    if (res.code == 200) {
                        this.projectList = res.data;
                    }
                });
                this.$api.getMaterialList({warehouseType: 0}).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data) {
                            item.checked = false;
                        }
                        this.tableList = res.data;
                        this.tableListStr = JSON.stringify(res.data);
                        for (const item1 of res.data) {
                            var item = {}
                            item.component = item1.component;
                            item.componentName = item1.componentName;
                            this.componentList.push(item);
                        }
                        this.componentList = this.unique(this.componentList)
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            delDevice(index) {
                this.deviceList.splice(index, 1)
            },
            addDevice(index) {
                this.deviceList.push({})
            },
            searchBasicBtn() {
                var newArr = [];
                var noChecked = [];
                // for (const item of JSON.parse(this.tableListStr)) {
                //     for (const item2 of this.multipleSelection) {
                //         if(item.id == item2.id){
                //             item.checked = true
                //             newArr.push(item)
                //         }else{
                //             item.checked = false
                //             noChecked.push(item);
                //         }
                //     }
                //     // if (item.checked == true) {
                //     //     newArr.push(item)
                //     //     return;
                //     // } else {
                //     //     noChecked.push(item);
                //     // }
                // }
                //console.log(newArr)
                noChecked = this.uniqueId(noChecked)
                for (var item of JSON.parse(this.tableListStr)) {
                    // if (this.searchBasicItem.materialCode != '' && this.searchBasicItem.materialCode != undefined
                    //     && this.searchBasicItem.component != '' && this.searchBasicItem.component != undefined) {
                    //     if (item.component.indexOf(this.searchBasicItem.component) > -1) {
                    //         if (item.materialCode.indexOf(this.searchBasicItem.materialCode) > -1) {
                    //             newArr.push(item)
                    //         }
                    //     }
                    // } else if (this.searchBasicItem.materialCode != '' && this.searchBasicItem.materialCode != undefined) {
                    //     if (item.materialCode.indexOf(this.searchBasicItem.materialCode) > -1) {
                    //         newArr.push(item)
                    //     }
                    // } else if (this.searchBasicItem.component != '' && this.searchBasicItem.component != undefined) {
                    //     if (item.component.indexOf(this.searchBasicItem.component) > -1) {
                    //         newArr.push(item)
                    //     }
                    // }

                    if(this.searchBasicItem.materialCode == '' && this.searchBasicItem.component == '' && this.searchBasicItem.materialName == ''){
                        return
                    }
                    if (this.searchBasicItem.materialCode != '' && item.materialCode.indexOf(this.searchBasicItem.materialCode) > -1) { 
                        if(this.searchBasicItem.component != '' && item.component.indexOf(this.searchBasicItem.component) > -1){
                            if(this.searchBasicItem.materialName != '' && item.materialName.indexOf(this.searchBasicItem.materialName) > -1){
                                newArr.push(item)
                            }else{
                                newArr.push(item)
                            }
                        }else{
                            if(this.searchBasicItem.materialName != '' && item.materialName.indexOf(this.searchBasicItem.materialName) > -1){
                                newArr.push(item)
                            }else{
                                newArr.push(item)
                            }
                        }
                    }else{
                        if(this.searchBasicItem.component != '' && item.component.indexOf(this.searchBasicItem.component) > -1){
                            if(this.searchBasicItem.materialName != '' && item.materialName.indexOf(this.searchBasicItem.materialName) > -1){
                                
                                newArr.push(item)
                            }else{
                                newArr.push(item)
                            }
                        }else{
                            if(this.searchBasicItem.materialName != '' && item.materialName.indexOf(this.searchBasicItem.materialName) > -1){
                                
                                newArr.push(item)
                            }else{

                            }
                        }
                    }
                }
                this.multipleSelection.map(item=>{
                    newArr.push(item)
                })
                // let flag = true
                // this.multipleSelection.map(item1=>{
                //     newArr.map((item2,index2)=>{
                //         if(item1.id == item2.id && flag){
                //             console.log('999')
                //             newArr.splice(index2,1)
                //             flag = false
                //         }
                //     })
                // })
                this.tableList = newArr
                // this.tableList = [...newArr,...this.tableList];
                // newArr.map(item1=>{
                //     this.tableList.map((item2,index2)=>{
                //         if(item2.id == item1.id){
                //             this.tableList.splice(index2,1)
                //         }
                //     })
                // })
                // newArr.map(item=>{
                //     this.tableList.unshift(item)
                // })
                // this.tableList = newArr;
                // var checkedArr = []
                // for (const item of newArr) {
                //     if (item.checked) {
                //         checkedArr.push(item)
                //     }
                // }
                this.toggleSelection(this.multipleSelection)
            },
            resetBasicBtn() {
                var allArr = JSON.parse(this.tableListStr);
                this.searchBasicItem = {
                    materialCode: '',
                    component: '',
                    materialName:''
                }
                for (const allArrElement of allArr) {
                    this.tableList.push(allArrElement)
                }
                this.tableList = this.uniqueId(this.tableList);
                var checkedArr = []
                for (const item of this.uniqueId(this.tableList)) {
                    if (item.checked) {
                        checkedArr.push(item)
                    }
                }
                this.toggleSelection(checkedArr)
            },
            unique(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].component]) {
                        newArr.push(arr[i]);
                        obj[arr[i].component] = true;
                    }
                }
                return newArr;
            },
            uniqueId(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].id]) {
                        newArr.push(arr[i]);
                        obj[arr[i].id] = true;
                    }
                }
                return newArr;
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            changeDateFormat(date) {
                if (date == null || date == '' || date == undefined) {
                    return '';
                } else {
                    let dt = new Date(date)
                    var year = dt.getFullYear();
                    var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                    var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                    return year + '-' + month + '-' + date
                }
            },
        }
        ,
        created() {
            this.initData()
            this.pageChange(1)
            this.tableHeight = (window.innerHeight) * 0.72
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    }
    ;
</script>
<style lang="scss" scoped>
.text-title {
        font-size: 16px;
        font-weight: bold;
        color: #437EE6;
        text-align: center;
        margin-bottom: 20px;
    }
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        color: #20a0ff;
        margin-right: 10px;
    }

    .icontingzhi {
        color: red;
        margin-left: 10px;
    }

    .button-style {
        color: blue;
        cursor: pointer;
        text-decoration: underline;
        width: 60px;
    }

    .icontongguo {
        margin-left: 10px;
        color: green;
    }

    .iconshanchu {
        margin-left: 10px;
        color: red;
    }

    .upload-file {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 90px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .operation {
        display: flex;
        justify-content: center;
    }

    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .info-dialog td {
        padding-top: 10px;
        text-align: left;
    }

    .td-style {
        width: 30%;
        white-space: normal; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .edit-body {
        max-height: 650px;
        overflow-y: auto;
    }

    .material-table, .table-material {
        margin-top: 10px;
    }
    .approval-table{
        margin-top: 50px;
    }
</style>